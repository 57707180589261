import HomePage from "@/home/pages/HomePage.vue"

const HomeRoutes = [
    {
        path: '/',
        name: 'HomePage',
        component: HomePage
    },
]

export default HomeRoutes