import LoginPage from "@/account/pages/login/LoginPage.vue"

const LoginRoutes = [
    {
        path: '/login',
        name: 'LoginPage',
        component: LoginPage
    },
]

export default LoginRoutes